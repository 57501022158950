import Vue from 'vue';
import router from '@router';
import store from '@store';
import Amplify, { Analytics } from '@src/lib/amplify';
import RulesLoader from '@src/rules';
import VueI18next from './translate/i18n';
import * as regions from '@utils/helper-region';
import PubSubPlugin from '@src/plugins/pubsub';
import BootstrapVue from 'bootstrap-vue';
import VueMoment from 'vue-moment';
import DatePicker from 'vue2-datepicker';
import moment from '@src/lib/moment';
import VueTheMask from 'vue-the-mask';
import VueMoney from 'v-money';
import '@utils/filters';
import VueSweetalert2 from 'vue-sweetalert2';
import VueViewer from 'v-viewer';
import MaskInput from '@components/mask-input';
import CustomDatePicker from '@components/date-picker';
import apolloClient from './utils/graphql';
import { copyText } from './utils/copyText';
import FleetProvider from './utils/fleetProvider';
import FleetRegulationProvider from './utils/fleetRegulationProvider';
import VueApollo from 'vue-apollo';
import NProgress from 'nprogress/nprogress';
import Snotify, { SnotifyPosition } from 'vue-snotify';
import Multiselect from 'vue-multiselect';
import '@src/plugins/vee-validate';
import VueApexCharts from 'vue-apexcharts';
import { VueSentryPlugin } from './lib/sentry';
import logManager, { VueLogManagerPlugin, LogLevelName } from './lib/logManager';
import spacetime from 'spacetime';
// import * as VueGoogleMaps from 'vue2-google-maps'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { GrowthBookVuePlugin } from './utils/growthbook/growthbook';

import App from '@src/app';

Vue.config.productionTip = false;

Vue.use(GrowthBookVuePlugin, {
  featuresEndpoint: `https://cdn.growthbook.io/api/features/${process.env.VUE_APP_GROWTHBOOK_SDK_KEY}`,
  enableDevMode: true,
});

Vue.component('multiselect', Multiselect);
Vue.component('datepicker', DatePicker);
Vue.component('date-picker', CustomDatePicker); // use this component as default compoent to dates
Vue.component('mask-input', MaskInput);
Vue.component('apexchart', VueApexCharts);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

Analytics.disable();
Vue.use(PubSubPlugin);
Vue.use(VueMoment, { moment });
Vue.use(BootstrapVue);
Vue.use(VueTheMask);
Vue.use(VueViewer);
Vue.use(FleetProvider);
Vue.use(FleetRegulationProvider);
Vue.use(VueMoney, {
  decimal: ',',
  thousands: '.',
  prefix: '',
  suffix: '',
  precision: 2,
  masked: false,
});
Vue.use(Amplify);
Vue.use(VueApollo);
Vue.use(VueSweetalert2);
Vue.use(Snotify, {
  toast: {
    position: SnotifyPosition.rightBottom,
    titleMaxLength: 25,
    bodyMaxLength: 50,
    timeout: 1700,
  },
});

const isProduction = process.env.RENTAL_ENV === 'prod';

Vue.config.productionTip = isProduction;

const logOptions = {
  showLogs: isProduction ? LogLevelName.None : LogLevelName.All,
};

Vue.use(VueLogManagerPlugin, logOptions);

export const $log = new logManager(logOptions);

Vue.use(VueSentryPlugin, {
  dsn: process.env.SENTRY_DSN,
  project: process.env.SENTRY_PROJECT,
  enabled: isProduction,
  Vue,
  config: {
    environment: process.env.RENTAL_ENV,
    blacklistUrls: [/google\.com/, /hotjar\.com/],
  },
});

Amplify.Logger.LOG_LEVEL = isProduction ? '' : 'DEBUG';

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      loadingKey: 'loading',
      fetchPolicy: 'cache-and-network',
    },
  },
  watchLoading(isLoading) {
    if (!isLoading) {
      NProgress.done();
    } else {
      NProgress.inc();
    }
  },
  errorHandler() {
    NProgress.done();
  },
});

Vue.router = router;

// instance properties
Vue.prototype.$rules = RulesLoader.rules;

// instance regions
Vue.prototype.$regions = { ...regions };

// spacetime lib
Vue.prototype.$spacetime = spacetime;

Vue.prototype.$copyText = copyText;

// eslint-disable-next-line
new Vue({
  router,
  store,
  apolloProvider,
  i18n: VueI18next,
  render: h => h(App),
}).$mount('#app');
